<template>
	<div>
		<Cover></Cover>

		<News></News>

		<v-img
			:src="require('@/assets/images/features/index_bg3.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<Rules></Rules>

		<v-img
			:src="require('@/assets/images/features/index_bg5.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<Timeline></Timeline>

		<v-img
			:src="require('@/assets/images/features/index_bg4.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<Contact></Contact>
	</div>
</template>



<script>
import Cover from "@/views/home/sections/Cover.vue";
import News from "@/views/home/sections/News.vue";
import Rules from "@/views/home/sections/Rules.vue";
import Timeline from "@/views/home/sections/Timeline.vue";
import Contact from "@/views/home/sections/Contact.vue";

export default {
	name: "Home",

	components: {
		Cover,
		News,
		Rules,
		Timeline,
		Contact
	},
};
</script>

<style scope>
.img_fixed .v-image__image {
	background-attachment: fixed !important;
}
</style>
