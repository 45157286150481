<template>
	<base-section>
		<v-container>
			<base-section-heading :title="$t('title-2')">
				{{ $t("subtitle-2") }}
			</base-section-heading>

			<v-row>
				<v-col v-for="card in cards" :key="card.title" cols="4">
					<base-info-card align="center" v-bind="card" />
				</v-col>
			</v-row>

			<div class="text-center">
				<v-btn color="primary" x-large tile to="/assets/docs/SFC2024_Rules_V1.1.pdf" target="_blank"
					><v-icon left> mdi-download </v-icon> Download the
					Rules</v-btn
				>
			</div>
		</v-container>
	</base-section>
</template>

<script>
export default {
	data: () => ({
		cards: [
			{
				icon: "mdi-pencil-ruler",
				title: "Design & Concept",
				subtitle: "Juried",
				text: "To encourage creative ideas and esthetic designs within the scope of the project, echoing the missions of the competition and the essence of the event.",
			},
			{
				icon: "mdi-account-hard-hat",
				title: "Engineering & Construction",
				subtitle: "Juried",
				text: "To ensure each component and element is properly erected and implemented as designed and all integrated systems function organically without use defects.",
			},
			{
				icon: "mdi-sprout",
				title: "Ecology & Habitat",
				subtitle: "Juried",
				text: "To compromise human development with ecological evolution, creating an inclusive environment serving not only human thrive but also native species.",
			},
			{
				icon: "mdi-air-filter",
				title: "Thermal & Air",
				subtitle: "Monitored",
				text: "To create a dynamically adjustable indoor environment, maintain air quality within thermal comfort zone and prevent occupants from sick building syndrome by natural or mechanical means.",
			},
			{
				icon: "mdi-solar-power",
				title: "Energy & Carbon",
				subtitle: "Monitored",
				text: "To improve the energy efficiency of the house and its affiliated systems and mitigate environmental harms of excessive resource use and carbon emissions, without sacrificing basic residential demands.",
			},
			{
				icon: "mdi-home-assistant",
				title: "Operation & Self-service",
				subtitle: "Task",
				text: "To demonstrate that the proposed prototype is user-friendly and fully operable, providing occupants with a pleasant living experience and handy move-in service.",
			},
			{
				icon: "mdi-weight-lifter",
				title: "Health & Well-being",
				subtitle: "Measured",
				text: "To advance physical and emotional health and inspire human delight through design interventions and operational protocols, fostering a culture of health and well-being.",
			},
			{
				icon: "mdi-broadcast",
				title: "Outreach & Impact",
				subtitle: "Juried",
				text: "To educate the public about the project and promote sustainable ideas worldwide via proactive communica-tion efforts, conveying the philosophy behind the prototype.",
			},
			{
				icon: "mdi-handshake",
				title: "Exploration & Interaction",
				subtitle: "Juried",
				text: "To interact with local resources and instruct occupants about indigenous culture and customs, as well as house features and healthy ways of recreation.",
			}
		],
	}),
};
</script>
