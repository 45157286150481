<template>
	<section>
		<v-theme-provider>
			<base-section id="social" class="text-center">
				<!-- <base-icon class="mb-8"> mdi-rss </base-icon> -->

				<base-section-heading :title="$t('contact')">
				</base-section-heading>

				<div>
					<p>
						<v-icon color="primary" class="mr-2"
							>mdi-phone-classic</v-icon
						>
						+86 (0)10 670 807 60
					</p>
					<p>
						<v-icon color="primary" class="mr-2">mdi-fax</v-icon>
						+86 (0)10 670 897 60
					</p>
					<p>
						<v-icon color="primary" class="mr-2">mdi-email</v-icon>
						SFC2024@163.com
					</p>
					<!-- <p>
						<v-icon color="primary" class="mr-2">mdi-web</v-icon>
						https://sdchina.org.cn
					</p> -->
					<p>
						<v-icon color="primary" class="mr-2"
							>mdi-map-marker</v-icon
						>
						{{ $t("address") }}
					</p>
				</div>
			</base-section>
		</v-theme-provider>
	</section>
</template>

<script>
export default {
	data: () => ({
		socials: [
			{
				name: "wechat",
				icon: "wechat",
			},
			{
				name: "weibo",
				icon: "sina-weibo",
			},
			{
				name: "tiktok",
				icon: "music-note-outline",
			},
		],
	}),
};
</script>
