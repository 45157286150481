<template>
	<base-section>
		<v-container>
			<base-section-heading :title="$t('title-3')">
				{{ $t("subtitle-3") }}
			</base-section-heading>

			<v-row>
				<v-col cols="2" v-for="(date, i) in dates" :key="i">
					<v-card outlined tile>
						<v-card-title
							class="font-title"
							:class="`${date.color}--text`"
						>
							<v-icon left :color="date.color">
								mdi-sign-direction
							</v-icon>
							{{ date.time }}
						</v-card-title>
						<v-card-text>
							{{ date.text }}
						</v-card-text>
						<v-card-actions
							><v-btn
								v-if="date.file"
								tile
								text
								small
								:color="date.color"
								:to="date.file"
								target="_blank"
								>{{ date.link }}</v-btn
							>
							<v-btn
								v-if="date.url"
								tile
								text
								small
								:color="date.color"
								:to="date.url"
								>{{ date.link }}</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</base-section>
</template>

<script>
export default {
	data: () => ({
		dates: [
			{
				time: "Oct 2023",
				text: "Release of Request for Proposals",
				link: "download",
				file: "/assets/docs/SFC2024_RFP.pdf",
				color: "primary",
			},
			{
				time: "Nov 2023",
				text: "Release of Rules",
				link: "download",
				file: "/assets/docs/SFC2024_Rules_V1.1.pdf",
				color: "primary",
			},
			{
				time: "Nov 2023",
				text: "Application Deadline",
				color: "",
			},
			{
				time: "Dec 2023",
				text: "Selection of Participating Teams",
				link: "Team List",
				url: "/news",
				color: "primary",
			},
			{
				time: "Dec 2023",
				text: "Technical Training Workshop",
				color: "",
			},
			{
				time: "2023 to 2024",
				text: "Technical Training Webinars",
				color: "",
			},
			{
				time: "Mar 2024",
				text: "Mid-term Evaluation (Onsite/Online)",
				color: "",
			},
			{
				time: "Jun to Jul 2024",
				text: "Foundation Construction",
				color: "",
			},
			{
				time: "Jul to Aug 2024",
				text: "Assembly & Testing",
				color: "",
			},
			{
				time: "Aug 2024",
				text: "Competition & Exhibition",
				color: "error",
				link: "Kangbao 2024",
			},
			{
				time: "Oct '24 to Sep '25",
				text: "Operation & Transfer",
				color: "",
			},
		],
	}),
};
</script>

<style scoped>
.font-title {
	font-family: "Adolphus";
}
</style>
