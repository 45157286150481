<template>
	<section>
		<v-img
			:src="require('@/assets/images/features/index_bg.png')"
			height="calc(100vh - 64px)"
			class="white--text img_fixed"
			gradient="to bottom, rgba(0, 0, 0, .8), rgba(0, 0, 0, .2)"
		>
			<v-container class="fill-height">
				<v-responsive class="mx-auto w-100 h-100" max-width="1080">
					<base-heading size="text-h1" style="max-width: 760px"
						>{{ $t("title") }}
					</base-heading>

					<base-subheading size="text-h4"
						>Teams Announced
					</base-subheading>
				</v-responsive>
			</v-container>
		</v-img>
	</section>
</template>

<script>
import Subheading from "@/components/base/Subheading.vue";
export default {
	components: { Subheading },

	provide: {
		theme: { isDark: true },
	},

	computed: {
		minHeight() {
			const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
			return `calc(${height} - ${this.$vuetify.application.top}px)`;
		},
	},
};
</script>

<style>
.img_fixed .v-image__image {
	background-attachment: fixed;
}
</style>